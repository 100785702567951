import React, { useEffect, useCallback, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Table } from "semantic-ui-react";
import { BACKEND } from "../url";

/**
 * mass yt embeds is too laggy to be practical, maybe lazy loading would work
 * @param props id
 * @returns
 */
function YouTubeEmbed(props: any) {
  return (
    <iframe
      width="420"
      height="315"
      src={`https://www.youtube.com/embed/${props.id}`}
    ></iframe>
  );
}

function YouTubeLink(props: any) {
  return <a href={`https://www.youtube.com/watch?v=${props.id}`}>{props.id}</a>;
}

export default function Sample() {
  const [samples, setSamples] = useState<any[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      const sampleRes: any[] = await fetch(`${BACKEND}/admin/sample`, {
        headers: {
          Authorization: `Basic ${btoa("sample:work")}`,
        },
      }).then((r) => r.json());

      setSamples(sampleRes);
    };

    fetchData();
  }, []);

  return (
    <div>
      <Table celled striped fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>twitch_name</Table.HeaderCell>
            <Table.HeaderCell width={1}>youtube video</Table.HeaderCell>
            <Table.HeaderCell width={2}>socials</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {samples
            .sort((a: any, b: any) => {
              const date1 = new Date(a.queue_time);
              const date2 = new Date(b.queue_time);
              return Number(date1) - Number(date2);
            })
            .map((s: any, index: number) => {
              const twitchName = <Table.Cell>{s.twitch_name}</Table.Cell>;
              const youtube = (
                <Table.Cell>
                  <YouTubeLink id={s.youtube_video_id}></YouTubeLink>
                </Table.Cell>
              );
              const socialMediaLinks = (
                <Table.Cell>
                  {s.socials.socials.map((ss: string) => {
                    return (
                      <div key={`ss-${ss}`}>
                        <a href={ss}>{ss}</a>
                      </div>
                    );
                  })}
                </Table.Cell>
              );
              return (
                <Table.Row key={`sample${s.twitch_name}-${index}`}>
                  {twitchName}
                  {youtube}
                  {socialMediaLinks}
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </div>
  );
}

// raw json
// <pre>{JSON.stringify(samples, null, 2)}</pre>
