import React, { useEffect, useCallback, useState } from "react";
import * as V from "victory";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isBetween from "dayjs/plugin/isBetween";
import { BrowserRouter as Router } from "react-router-dom";
import { BACKEND } from "../url";
dayjs.extend(weekOfYear);
dayjs.extend(isBetween);

type paymentProps = {
  payments: Record<string, any>[];
};
type taskProps = {
  tasks: Record<string, any>[];
};
function TasksPie(props: taskProps) {
  const counts: Record<string, any> = {};
  props.tasks.forEach((t) => {
    if (t.status in counts) {
      counts[t.status as string] += 1;
    } else {
      counts[t.status as string] = 1;
    }
  });
  const p: any[] = [];
  for (const [k, v] of Object.entries(counts)) {
    p.push({
      x: k,
      y: v,
    });
  }

  return (
    <V.VictoryPie
      style={{
        labels: {
          fontSize: 8,
        },
      }}
      height={200}
      data={p}
    />
  );
}
function PaymentsPie(props: paymentProps) {
  const counts: Record<string, any> = {};
  props.payments.forEach((p) => {
    if (p.payment_amount in counts) {
      counts[p.payment_amount as string] += 1;
    } else {
      counts[p.payment_amount as string] = 1;
    }
  });
  const p: any[] = [];
  for (const [k, v] of Object.entries(counts)) {
    p.push({
      x: k,
      y: v,
    });
  }

  return (
    <V.VictoryPie
      style={{
        labels: {
          fontSize: 8,
        },
      }}
      height={200}
      data={p}
    />
  );
}
function TasksLine(props: taskProps) {
  const count: any = {};
  props.tasks.forEach((t) => {
    const week = dayjs(t.queue_time).week();
    if (week in count) {
      count[week] += 1;
    } else {
      count[week] = 1;
    }
  });

  const p: any[] = [];
  for (const [k, v] of Object.entries(count)) {
    p.push({
      x: k,
      y: v,
    });
  }
  return (
    <V.VictoryChart>
      <V.VictoryLine
        style={{
          data: { stroke: "#c43a31" },
          parent: { border: "1px solid #ccc" },
        }}
        data={p}
        height={200}
      />
    </V.VictoryChart>
  );
}

function PaymentsLine(props: paymentProps) {
  const count: any = {};
  props.payments.forEach((p) => {
    const week = dayjs(p.create_time).week();
    if (week in count) {
      count[week] += 1;
    } else {
      count[week] = 1;
    }
  });

  const p: any[] = [];
  for (const [k, v] of Object.entries(count)) {
    p.push({
      x: k,
      y: v,
    });
  }
  return (
    <V.VictoryChart>
      <V.VictoryLine
        style={{
          data: { stroke: "#c43a31" },
          parent: { border: "1px solid #ccc" },
        }}
        data={p}
        height={200}
      />
    </V.VictoryChart>
  );
}

export default function Charts() {
  const [payments, setPayments] = useState<any[]>([]);
  const [tasks, setTasks] = useState<any[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      const paymentRes: any[] = await fetch(`${BACKEND}/admin/payment`, {
        headers: {
          Authorization: `Basic ${btoa("sample:work")}`,
        },
      }).then((r) => r.json());

      setPayments(
        paymentRes.filter((p) => {
          const now = dayjs();
          const yearAgo = dayjs().subtract(1, "year");
          return dayjs(p.create_time).isBetween(now, yearAgo);
        })
      );

      const taskRes: any[] = await fetch(`${BACKEND}/admin/task`, {
        mode: "no-cors",
      }).then((r) => r.json());

      setTasks(
        taskRes.filter((t) => {
          const now = dayjs();
          const yearAgo = dayjs().subtract(1, "year");
          return dayjs(t.queue_time).isBetween(now, yearAgo);
        })
      );
    };

    fetchData();
  }, []);

  return (
    <>
      <PaymentsLine payments={payments} />
      <PaymentsPie payments={payments} />
      <TasksLine tasks={tasks} />
      <TasksPie tasks={tasks} />
    </>
  );
}
