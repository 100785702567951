import React, { useEffect, useCallback, useState } from "react";
import { Switch, Route, BrowserRouter as Router, Link } from "react-router-dom";
import Charts from "./components/Charts";
import Sample from "./components/Sample";

import "semantic-ui-css/semantic.min.css";

export default function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <Sample />
          </Route>
          <Route exact path="/charts">
            <Charts />
          </Route>
          <Route path="/samples">
            <Sample />
          </Route>
        </Switch>
      </Router>
    </>
  );
}
